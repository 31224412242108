<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <edit-account></edit-account>
            <div style="min-height:13px;"></div>

            <!-- email naslovi za obveščanje o novih dovolilnicah ipd. -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md10 lg10 xl8>
                    <v-form id="form_notification_settings" ref="form_notification_settings" v-model="form1" lazy-validation>
                        <v-card>
                            <v-card-title>
                                    <span class="subtitle-1"><v-icon left>mdi-tune</v-icon> Nastavitve za obveščanje</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="toggleCardContent('card1')">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <span class="body-2 font-weight-regular"><v-icon class="" medium color="secondary" left>mdi-information</v-icon> {{ settings_text }}</span>
                                <div class="div__divider__20"></div>
                                <v-textarea
                                outlined
                                name="input-7-4"
                                label="E-mail naslovi za parkirno dovolilnico"
                                v-model="textareaParking"
                                ></v-textarea>
                                
                                <v-textarea
                                outlined
                                name="input-7-4"
                                label="E-mail naslovi za dovolilnico za dostop na promenado"
                                v-model="textareaPromenada"
                                ></v-textarea>
                            </v-card-text>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1"  v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn :disabled="loading" @click="saveSettings()" color="success" block outlined style="margin-bottom:10px;"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                                <v-btn :disabled="loading" @click="sendTestMail()" color="info" block outlined style="margin-bottom:10px;"><v-icon left>mdi-email-outline</v-icon>Testni Email</v-btn>
                                <v-btn :disabled="loading" @click="cancelSettings()" color="error" block outlined><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                            </v-card-text>
                            <v-card-actions v-show="card1" v-else>
                                <v-btn :disabled="loading" @click="saveSettings()" color="success" text><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                                <v-btn :disabled="loading" @click="sendTestMail()" color="info" text><v-icon left>mdi-email-outline</v-icon>Testni email</v-btn>
                                <v-btn :disabled="loading" @click="cancelSettings()" color="error" text><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                            </v-card-actions>
                            <div style="min-height:5px;"></div>
                        </v-card>
                    </v-form>
                </v-flex>
            </v-layout>

        </v-container>
    </div>
</template>

<script>
const EditAccount = () => import('@/components/EditAccount.vue')

export default {
    data: () => ({
        form1: true,
        card1: true,
        card2: true,
        loading: false,
        settings_text: 'V spodnja polja vnesite e-mail naslove, na katere želite prejemati vsa obvestila povezana z oddajo vlog in ostalih vrst obveščanj (npr. obvestilo o poteku dovolilnice, obvestilo o prejemu plačila dovolilnice, ipd.). Vneseni e-mail naslovi naj bodo ločeni z vejico. Primer vnosa: info@conforma.si,podpora@conforma.si,obvescanje@conforma.si',
        textareaParking: null,
        textareaPromenada: null
    }),

    components: {
        EditAccount
    },

    computed: {
    
    },

    methods: {
        toggleCardContent(card) {
            if(card === 'card1') {
                this.card1 = !this.card1
            }
        },

        getSettings() {
            var vm = this
            vm.loading = true
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('GET_EMAIL_NOTIFICATION_SETTINGS')
            .then(response => {
                //window.console.log(response.data)
                vm.textareaParking = response.data.app_parking_emails
                vm.textareaPromenada = response.data.app_promenada_emails
            })
            .catch(error => {
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri pridobivanju podatkov od nastavitvah za obveščanje je prišlo do napake. Kliknite gumb Prekliči za ponovno pridobivanje podatkov.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.loading = false
                    vm.$store.commit('SET_PROGRESS', false)
                }, 600)
            })
        },

        saveSettings() {
            var vm = this
            vm.loading = true
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.commit('SET_LOADER_TEXT', 'Posodobitev nastavitev za obveščanje je v teku...')
            vm.$store.commit('SET_LOADER', true)
            vm.$store.dispatch('STORE_EMAIL_NOTIFICATION_SETTINGS', {
                app_parking_emails: vm.textareaParking,
                app_promenada_emails: vm.textareaPromenada

            })
            .then(response => {
                //window.console.log(response.data)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Posodabljanje nastavitev za obveščanje je uspelo")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
            })
            .catch(error => {
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri shranjevanju nastavitev za obveščanje je prišlo do napake. Prosimo poskusite ponovno.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
            })
            .finally(() => {
                vm.$store.commit('SET_LOADER', false)
                setTimeout(() => {
                    vm.loading = false
                    vm.$store.commit('SET_PROGRESS', false)
                }, 600)
            })
        },

        cancelSettings() {
            this.getSettings();
        },

        sendTestMail() {

        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
    },

    mounted() {
        this.getSettings()
    },

    destroyed() {

    }
}

</script>

<style>

</style>